import { all } from 'redux-saga/effects'

import bancos from './bancos/sagas'
import clientes from './clientes/sagas'
import dashboard from './dashboard/sagas'
import peticiones from './peticiones/sagas'
import empresas from './empresas/sagas'
import menu from './menu/sagas'
import nominas from './nominas/sagas'
import nominasPendientes from './nominasPendientes/sagas'
import periodos from './periodos/sagas'
import settings from './settings/sagas'
import user from './user/sagas'
import usuarios from './usuarios/sagas'
import solicitudes from './solicitudes/sagas'

export default function* rootSaga() {
  yield all([
    bancos(),
    clientes(),
    dashboard(),
    empresas(),
    menu(),
    nominas(),
    nominasPendientes(),
    periodos(),
    settings(),
    peticiones(),
    user(),
    usuarios(),
    solicitudes(),
  ])
}
