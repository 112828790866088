import React from 'react'
import { LocaleProvider } from 'antd'
import { IntlProvider, addLocaleData } from 'react-intl'
import { connect } from 'react-redux'
import english from 'locales/en-US'
import spanish from 'locales/es-LA'

import moment from 'moment';
import 'moment/locale/es-us';

moment.locale('es-us');

addLocaleData(spanish.localeData)
addLocaleData(english.localeData)
// addLocaleData(french.localeData)
// addLocaleData(russian.localeData)
// addLocaleData(chinese.localeData)

const locales = {
  'es-LA': spanish,
  'en-US': english,
}

@connect(({ settings }) => ({ settings }))
class Localization extends React.Component {
  render() {
    const {
      children,
      settings: { locale },
    } = this.props
    const currentLocale = locales[locale]
    return (
      <LocaleProvider locale={currentLocale.antdData}>
        <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
          {children}
        </IntlProvider>
      </LocaleProvider>
    )
  }
}

export default Localization
