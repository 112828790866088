import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'
import { hot } from 'react-hot-loader/root'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // System Pages
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },

  {
    path: '/dashboard',
    component: loadable(() => import('pages/dashboard')),
    exact: true,
  },

  // Apps

  {
    path: '/adc',
    component: loadable(() => import('pages/adc')),
    exact: true,
  },
  {
    path: '/provisiones',
    component: loadable(() => import('pages/provisiones')),
    exact: true,
  },
  {
    path: '/provisiones/:idCliente',
    component: loadable(() => import('pages/provisiones/details')),
    exact: true,
  },
  {
    path: '/calendario/:frecuencia/:number/:rangeStart/:rangeEnd',
    component: loadable(() => import('pages/details')),
    exact: true,
  },
  {
    path: '/calendario',
    component: loadable(() => import('pages/calendar')),
    exact: true,
  },
  {
    path: '/reloj',
    component: loadable(() => import('pages/reloj')),
    exact: true,
  },
  {
    path: '/facturacion',
    component: loadable(() => import('pages/facturacion')),
    exact: true,
  },

  {
    path: '/usuarios',
    component: loadable(() => import('pages/usuarios')),
    exact: true,
  },
  {
    path: '/nominas',
    component: loadable(() => import('pages/nominas')),
    exact: true,
  },
  {
    path: '/clave-nominas',
    component: loadable(() => import('pages/clave-nominas')),
    exact: true,
  },
  {
    path: '/nominas-emisora',
    component: loadable(() => import('pages/nominas-emisora')),
    exact: true,
  },
  {
    path: '/plana-nominas',
    component: loadable(() => import('pages/plana-nominas')),
    exact: true,
  },
  {
    path: '/facturaciones-pendientes',
    component: loadable(() => import('pages/facturaciones-pendientes')),
    exact: true,
  },
  {
    path: '/nominas-pendientes',
    component: loadable(() => import('pages/nominas-pendientes')),
    exact: true,
  },
  {
    path: '/empresas',
    component: loadable(() => import('pages/empresas')),
    exact: true,
  },
  {
    path: '/clientes',
    component: loadable(() => import('pages/clientes')),
    exact: true,
  },
  {
    path: '/bancos',
    component: loadable(() => import('pages/bancos')),
    exact: true,
  },
  {
    path: '/peticiones',
    component: loadable(() => import('pages/peticiones')),
    exact: true,
  },
  {
    path: '/procesos',
    component: loadable(() => import('pages/procesos')),
    exact: true,
  },
  {
    path: '/comercial',
    component: loadable(() => import('pages/comercial')),
    exact: true,
  },
  {
    path: '/solicitudes',
    component: loadable(() => import('pages/solicitudes')),
    exact: true,
  },
  {
    path: '/solicitudes/nueva',
    component: loadable(() => import('pages/solicitudes-nueva')),
    exact: true,
  },
  {
    path: '/periodos',
    component: loadable(() => import('pages/periodos')),
    exact: true,
  },
]

class Router extends React.Component {
  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
            {routes.map((route) => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default hot(Router)
