/* eslint-disable global-require */
import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import store from 'store'
import { Scrollbars } from 'react-custom-scrollbars'
import _ from 'lodash'
import styles from './style.module.scss'

const { Sider } = Layout
const { SubMenu, Divider } = Menu

const icons = {
  'dashboard': require('../../../../assets/icons/dashboard.svg'),
  'reloj': require('../../../../assets/icons/reloj.svg'),
  'bell': require('../../../../assets/icons/bell.svg'),
  'pen': require('../../../../assets/icons/pen.svg'),
  'bill': require('../../../../assets/icons/bill.svg'),
  'solicitudes': require('../../../../assets/icons/solicitudes.svg'),
  'comercial': require('../../../../assets/icons/comercial.svg'),

  'customer-support': require('../../../../assets/icons/customer-support.svg'),
  'settlement': require('../../../../assets/icons/settlement.svg'),
  'process': require('../../../../assets/icons/process.svg'),
  'proviciones': require('../../../../assets/icons/proviciones.svg'),
  'calendar': require('../../../../assets/icons/calendar.svg'),
  'timeline': require('../../../../assets/icons/timeline.svg'),
  'office': require('../../../../assets/icons/office.svg'),
  'users': require('../../../../assets/icons/users.svg'),
  'banks': require('../../../../assets/icons/banks.svg'),
  'clients': require('../../../../assets/icons/clients.svg'),
  'payroll': require('../../../../assets/icons/payroll.svg'),
  'periods': require('../../../../assets/icons/periods.svg'),
}

const mapStateToProps = ({ menu, settings, user, nominasPendientes }) => ({
  menuData: menu.menuLeftData,
  nominasPendientes,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isSettingsOpen: settings.isSettingsOpen,
  isLightTheme: settings.isLightTheme,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  // me,
  puesto: user.puesto,
  rol: user.rol,
  name: user.nombre,
})

@withRouter
@connect(mapStateToProps)
class MenuLeft extends React.Component {
  state = {
    selectedKeys: store.get('app.menu.selectedKeys') || [],
    openedKeys: store.get('app.menu.openedKeys') || [],
  }

  componentWillMount() {
    this.setSelectedKeys(this.props)
  }

  componentWillReceiveProps(newProps) {
    if (newProps.isMenuCollapsed && !newProps.isMobileView) {
      this.setState({
        openedKeys: [],
      })
    }
    this.setSelectedKeys(newProps)
  }

  setSelectedKeys = props => {
    const { menuData } = this.props
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = _.find(flattenItems(menuData, 'children'), [
      'url',
      props.location.pathname,
    ])
    this.setState({
      selectedKeys: selectedItem ? [selectedItem.key] : [],
    })
  }

  onCollapse = (value, type) => {
    const { dispatch, isMenuCollapsed } = this.props
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }

    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })

    this.setState({
      openedKeys: [],
    })
  }

  onOpenChange = openedKeys => {
    store.set('app.menu.openedKeys', openedKeys)
    this.setState({
      openedKeys,
    })
  }

  handleClick = e => {
    const { dispatch, isSettingsOpen } = this.props
    store.set('app.menu.selectedKeys', [e.key])
    // custom action on settings menu item
    if (e.key === 'settings') {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isSettingsOpen',
          value: !isSettingsOpen,
        },
      })
      return
    }
    this.setState({
      selectedKeys: [e.key],
      // openKeys: e.keyPath,
    })
  }

  generateMenuItems = () => {
    const { menuData = [], puesto = "", rol, nominasPendientes: { entities: nominasPendientes = [] } } = this.props
    const badges = {
      nominasPendientes: nominasPendientes.length
    }
    const generateItem = item => {
      const { key, title, url, icon, iconSVG, disabled, puestos, keyBadge } = item

      if (rol !== 'Administrador' && puestos) {
        const puedeVer = puestos.find(p => puesto.toLowerCase().indexOf(p) > -1)
        if (!puedeVer) {
          return null;
        }
      }

      if (item.divider) {
        return <Divider key={Math.random()} />
      }

      let source;
      if (iconSVG) {
        source = icons[iconSVG]
      }

      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target ? (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
                <span className={styles.title}>{title} {badges[keyBadge]}</span>
                {badges[keyBadge] && (
                  <span className="badge badge-primary badge-collapsed-hidden ml-2">{badges[keyBadge]}</span>
                )}
              </a>
            ) :
              (
                <Link to={url}>
                  <div style={{ display: 'flex', alignItems: 'stretch', margin: '0px -5px' }}>
                    {icon && !iconSVG && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
                    {iconSVG && <span
                      style={{
                        backgroundImage: `url(${source})`,
                        width: 25,
                        height: 40,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center center',
                      }}
                      className="icon-collapsed-hidden"
                    />}
                    <span style={{ marginLeft: 10, marginRight: 10 }} className={styles.title}>{title}</span>
                  </div>
                </Link>
              )
            }
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
          <span className={styles.title}>{title}</span>
        </Menu.Item>
      )
    }

    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={styles.title}>{menuItem.title}</span>
              {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
            </span>
          )
          return (
            <SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return menuData.map(menuItem => {
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={styles.title}>{menuItem.title}</span>
            {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
          </span>
        )
        return (
          <SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  render() {
    const { selectedKeys, openedKeys } = this.state
    const { isMobileView, isMenuCollapsed, isLightTheme } = this.props
    const menuSettings = isMobileView
      ? {
        width: 256,
        collapsible: false,
        collapsed: false,
        onCollapse: this.onCollapse,
      }
      : {
        width: 256,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse: this.onCollapse,
        breakpoint: 'lg',
      }

    const menu = this.generateMenuItems()

    return (
      <Sider
        {...menuSettings}
        className={isLightTheme ? `${styles.menu} ${styles.light}` : styles.menu}
      >
        <div className={styles.logo}>
          <div className={styles.logoContainer}>
            <img
              src={`/resources/images/logo${menuSettings.collapsed ? '-mobile' : ''}.png`}
              alt=""
            />
          </div>
        </div>
        <Scrollbars
          className={isMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                width: '4px',
                borderRadius: 'inherit',
                backgroundColor: '#c5cdd2',
                left: '1px',
              }}
            />
          )}
          autoHide
        >
          <Menu
            theme={isLightTheme ? 'light' : 'dark'}
            onClick={this.handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={this.onOpenChange}
            mode="inline"
            className={styles.navigation}
          >
            {menu}
          </Menu>
        </Scrollbars>
      </Sider>
    )
  }
}

export default MenuLeft
