
import React from 'react';
import { FormattedMessage } from 'react-intl';

export async function getLeftMenuData() {
  return [
    {
      title: <FormattedMessage id="menu.dashboard" />,
      key: 'dashboard',
      url: '/dashboard',
      icon: 'icmn icmn-meter',
      iconSVG: 'dashboard',
      puestos: ['a', 'e', 'i', 'o', 'u'],
    },
    {
      title: "Checador",
      key: 'reloj',
      url: '/reloj',
      icon: 'icmn icmn-clock',
      iconSVG: 'reloj',
      puestos: ['a', 'e', 'i', 'o', 'u'],
    },
    
    {
      title: <FormattedMessage id="menu.nominasPendientes" />,
      key: 'nominas-pendientes',
      url: '/nominas-pendientes',
      icon: 'icmn icmn-bell',
      keyBadge: 'nominasPendientes',
      iconSVG: 'bell',
      puestos: ['a', 'e', 'i', 'o', 'u'],
    },

    {
      title: <FormattedMessage id="menu.peticiones" />,
      key: 'peticiones',
      url: '/peticiones',
      icon: 'icmn icmn-pen',
      iconSVG: 'pen',
      puestos: ['direc', 'coor', 'gerente'],
    },

    {
      divider: true,
    },

    {
      title: <FormattedMessage id="menu.calendar" />,
      key: 'calendar',
      url: '/calendario',
      icon: 'icmn icmn-calendar',
      iconSVG: 'timeline',
      puestos: ['a', 'e', 'i', 'o', 'u'],
    },

    {
      title: <FormattedMessage id="menu.solicitudes" />,
      key: 'solicitudes',
      url: '/solicitudes',
      icon: 'icmn icmn-pen',
      iconSVG: 'solicitudes',
      puestos: ['cuenta', 'comercial', 'operaci'],
    },

    {
      title: <FormattedMessage id="menu.ADC" />,
      key: 'adcSection',
      url: '/adc',
      keyBadge: 'adcSection',
      iconSVG: 'customer-support',
      puestos: ['cuenta', 'comercial', 'operaci'],
    },
    {
      title: <FormattedMessage id="menu.provisiones" />,
      key: 'provisiones',
      url: '/provisiones',
      iconSVG: 'proviciones',
      icon: 'icmn icmn-address-book',
      puestos: ['venta', 'proc', 'calidad', 'coor', 'gerente'],
    },
    {
      title: "Facturación",
      key: 'facturacionSection',
      url: '/facturaciones-pendientes',
      keyBadge: 'facturacionSection',
      iconSVG: 'bill',
      puestos: ['factur'],
    },
    {
      title: 'Comercial',
      key: 'comercial',
      url: '/comercial',
      keyBadge: 'comercial',
      iconSVG: 'comercial',
      puestos: ['proce', 'ope', 'come', 'gener'],
    },
    // {
    //   title: 'Procesos',
    //   key: 'procesos',
    //   url: '/procesos',
    //   keyBadge: 'procesos',
    //   iconSVG: 'process',
    //   puestos: ['proce', 'ope', 'come', 'gener'],
    // },

    // {
    //   title: 'Finiquitos',
    //   key: 'settlement',
    //   url: '/finiquitos',
    //   icon: 'icmn icmn-calendar',
    //   iconSVG: 'settlement',
    //   puestos: ['a', 'e', 'i', 'o', 'u'],
    // },
    // {
    //   title: <FormattedMessage id="menu.settlements" />,
    //   key: 'settlements',
    //   url: '/settlements',
    //   icon: 'icmn icmn-stack',
    //   puestos: [],
    // },

    {
      divider: true,
    },

    {
      title: <FormattedMessage id="menu.usuarios" />,
      key: 'usuarios',
      url: '/usuarios',
      iconSVG: 'users',
      icon: 'icmn icmn-users',
      puestos: ["ti"],
    },

    {
      title: <FormattedMessage id="menu.empresas" />,
      key: 'empresas',
      url: '/empresas',
      icon: 'icmn icmn-office',
      iconSVG: 'office',

      puestos: ['cuenta', 'coord', 'proc', 'calidad'],
    },
    {
      title: <FormattedMessage id="menu.clientes" />,
      key: 'clientes',
      url: '/clientes',
      iconSVG: 'clients',
      icon: 'icmn icmn-address-book',
      puestos: ['venta', 'proc', 'calidad', 'coor', 'gerente'],
    },
    
    {
      title: <FormattedMessage id="menu.bancos" />,
      key: 'bancos',
      url: '/bancos',
      iconSVG: 'banks',
      icon: 'icmn icmn-library',
      puestos: ['cuenta', 'calidad', 'disper'],
    },
    {
      title: <FormattedMessage id="menu.periodos" />,
      key: 'periodos',
      url: '/periodos',
      iconSVG: 'periods',
      icon: 'icmn icmn-calendar',
      puestos: ['cuenta'],
    },
    {
      title: <FormattedMessage id="menu.nominas" />,
      key: 'nominas',
      url: '/nominas',
      iconSVG: 'payroll',
      
      icon: 'icmn icmn-books',
      puestos: ['personal', 'gerente', "cuenta", 'coor', 'disper', 'proc', 'calidad'],
    },
    // {
    //   title: <FormattedMessage id="menu.claveNominas" />,
    //   key: 'claveNominas',
    //   iconSVG: 'payroll',
    //   url: '/clave-nominas',
    //   icon: 'icmn icmn-books',
    //   puestos: ["nomi"],
    // },
    // {
    //   title: <FormattedMessage id="menu.planaNominas" />,
    //   key: 'planaNominas',
    //   iconSVG: 'payroll',
    //   url: '/plana-nominas',
    //   icon: 'icmn icmn-books',
    //   puestos: ["cuenta"],
    // },
  ]
}

export async function getTopMenuData() {
  return [
    {
      title: <FormattedMessage id="menu.dashboard" />,
      key: 'dashboard',
      url: '/dashboard',
      icon: 'icmn icmn-meter',
      puestos: [],
    },
    {
      title: <FormattedMessage id="menu.calendar" />,
      key: 'calendar',
      url: '/calendario',
      icon: 'icmn icmn-calendar',
      puestos: [],
    },
    {
      title: <FormattedMessage id="menu.settlements" />,
      key: 'settlements',
      url: '/settlements',
      icon: 'icmn icmn-stack',
      puestos: [],
    },

  ]
}
