import actions from './actions'

const initialState = {
  entities: [],
  index: {},
  loading: false,
}

export function clientes(state = initialState, action) {
  let index
  switch (action.type) {
    case actions.SET_STATE:
      if (action.payload.entities) {
        index = action.payload.entities.reduce((ac, ent) => {
          return { ...ac, [ent.id]: ent }
        }, {})
      }

      return {
        ...state,
        ...action.payload,
        index,
      }
    default:
      return state
  }
}

export function misClientes(state = initialState, action) {
  let index
  switch (action.type) {
    case actions.SET_MI_STATE:
      if (action.payload.entities) {
        index = action.payload.entities.reduce((ac, ent) => {
          return { ...ac, [ent.id]: ent }
        }, {})
      }

      return {
        ...state,
        ...action.payload,
        index,
      }
    default:
      return state
  }
}
